import React, { useState }  from 'react'
import 'spectre.css'
import 'spectre.css/dist/spectre-icons.css'
import Preview from './components/preview'
import createUrl from './utilities/create_url'
import encodeImage from './utilities/encode_image'
import Tabs from './components/tabs'
import TemplateUpload from './components/template_upload'
import TemplateFile from './components/template_file'
import TemplateColor from './components/template_color'

const templateColorsWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  backgroundColor: '#ddd',
  padding: 5,
  minHeight: 'calc(100vh - 108px)'
}

const headerWrapperStyle = {
  marginBottom: 20,
  marginTop: 20,
  display: 'flex',
  gap: 10,
  justifyContent: 'end'
}

const Template = () => {
  const [primaryColor, setPrimaryColor] = useState('#1a4f90'),
    [link, setlinkColor] = useState('#3b5998'),
    [headerBackground, setHeaderBackgroundColor] = useState('#262262'),
    [publicBackground, setpublicBackgroundColor] = useState('#262262'),
    [headerLogo, _setLogo] = useState(null),
    [loginBrandImage, _setLoginBrandImage] = useState(null),
    [mobileLogo, _setMobileLogo] = useState(null),
    [mobileHeaderLogo, _setMobileHeaderLogo] = useState(null),
    [name, setName] = useState(''),
    [showPage, setShowPage] = useState({page: 'HomePage'}),
    [createdAt, setCreatedAt] = useState(null);

  const setLogo = async (file) => { _setLogo(await encodeImage(file))}
  const setLoginBrandImage = async (file) => { _setLoginBrandImage(await  encodeImage(file))}
  const setMobileLogo = async (file) => { _setMobileLogo(await  encodeImage(file))}
  const setMobileHeaderLogo = async (file) => { _setMobileHeaderLogo(await  encodeImage(file))}

  const urlProps = {
    name,
    primaryColor,
    link,
    headerBackground,
    publicBackground,
    headerLogo,
    loginBrandImage,
    mobileLogo,
    mobileHeaderLogo,
    createdAt
  }

  return (
    <div style={{width: 325 + 1040 + 10, margin: 'auto', display: 'flex', flexDirection: 'column'}}>
      <div style={headerWrapperStyle}>
        <h1 style={{flexGrow: 1}}>
          Trusted Design Center
        </h1>
        <a
          href={URL.createObjectURL(createUrl(urlProps))}
          download={name}
          className='btn btn-lg btn-success'>
          Download Template
        </a>
        <TemplateUpload
          setName={setName}
          setPrimaryColor={setPrimaryColor}
          setlinkColor={setlinkColor}
          setHeaderBackgroundColor={setHeaderBackgroundColor}
          setpublicBackgroundColor={setpublicBackgroundColor}
          setLogo={_setLogo}
          setCreatedAt={setCreatedAt}
          setLoginBrandImage={_setLoginBrandImage}
          setMobileLogo={_setMobileLogo}
          setMobileHeaderLogo={_setMobileHeaderLogo}/>
      </div>

      <div style={{display: 'grid', gridTemplateColumns: '325px 1040px', columnGap: 10}}>
        <div style={templateColorsWrapperStyle}>
          <div style={{marginBottom: 10}}>
            Template Name<br />
            <input
              type='text'
              style={{width: '100%'}}
              value={name}
              placeholder='template'
              onChange={(e) => setName(e.target.value)}/>
          </div>
          <TemplateColor
            name='Primary'
            color={primaryColor}
            onChange={setPrimaryColor} />
          <TemplateColor
            name='Link'
            color={link}
            onChange={setlinkColor} />
          <TemplateColor
            name='Header'
            color={headerBackground}
            onChange={setHeaderBackgroundColor} />
          <TemplateColor
            name='Public'
            color={publicBackground}
            onChange={setpublicBackgroundColor} />
          <TemplateFile
            name='Header logo'
            preferredType='svg'
            image={headerLogo}
            setImage={(s) => { setLogo(s); setShowPage({page: 'HomePage'}); }}/>
          <TemplateFile
            name='Login Image'
            preferredType='svg'
            image={loginBrandImage}
            setImage={(s) => { setLoginBrandImage(s); setShowPage({page: 'LoginPage'}); }}/>
          <TemplateFile
            name='Mobile Logo'
            preferredType='png'
            image={mobileLogo}
            setImage={(s) => { setMobileLogo(s); setShowPage({page: 'Iphone'}); }}/>
          <TemplateFile
            name='Mobile Header'
            preferredType='png'
            image={mobileHeaderLogo}
            setImage={(s) => { setMobileHeaderLogo(s); setShowPage({page: 'Iphone'}); }}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Tabs
            showPage={showPage}
            setShowPage={setShowPage}
          />
          <hr />
          <Preview
            headerBackground={headerBackground}
            showPage={showPage}
            link={link}
            name={name}
            mobileLogo={mobileLogo}
            brandImage={loginBrandImage}
            loginBrandImage={loginBrandImage}
            publicBackground={publicBackground}
            headerLogo={headerLogo}
            mobileHeaderLogo={mobileHeaderLogo}
            primaryColor={primaryColor} />
        </div>
      </div>
    </div>
  )
}

export default Template
