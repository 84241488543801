import React from 'react'

const svgConfirmMsg = "If possible, please use an SVG for the best user experience",
  pngConfirmMsg = "Please use a PNG (or GIF) for mobile compatability."

const removeImageButtonStyle = {
  display: 'inline-block',
  border: '2px solid #9B182A',
  borderRadius: 5,
  color: 'white',
  backgroundColor: '#D6213A',
  width: '100%',
  verticalAlign: 'middle',
  textAlign: 'center',
  cursor: 'pointer'
}

const TemplateFile = ({setImage, image, name, preferredType}) => {
  const msgConfirm = preferredType === 'svg' ? svgConfirmMsg : pngConfirmMsg,
    msgType = preferredType === 'svg' ? 'image/svg+xml' : 'image/png'

  return (
    <div >
      <label
        style={{textAlign: 'center'}}
        htmlFor={`file.${'ds'}`}>
        {name}
      </label>
      <div className='form-group'>
        {
          image ?
            (
              <button
                style={removeImageButtonStyle}
                onClick={ () => setImage(null) }
              >
                Remove
              </button>
            ) : (
              <input
                className={`form-input`}
                type='file'
                name='no'
                style={{display: 'inline-block'}}
                onChange={(e) => {
                  const file = e.target.files[0]
                  if (file.type !== msgType) {
                    window.confirm(msgConfirm)
                  }

                  setImage(file)}
                } />
            )
        }
      </div>
    </div>
  )
}

export default TemplateFile
