import React from 'react'
import comments from '../utilities/images/comments.png'
import george from '../utilities/images/george.jpg'
import topics from '../utilities/images/topics.png'
import shareStory from '../utilities/images/share_story.png'
import video from '../utilities/images/video.png'
import Plus from '../utilities/images/plus'
import search from '../utilities/images/search.png'
import topRightStuff from '../utilities/images/top_right.png'

const newsItemWrapperStyle = {
  width: 460,
  height: 500,
  backgroundColor: 'white',
  marginTop: 50,
  marginLeft: 50
}

const newsItemHeaderStyle = {
  width: 460,
  borderBottom: '1px solid grey',
  height: 50,
  padding: 5,
  display: 'inline-flex'
}

const otherStuff = {
  width: 200,
  backgroundColor: 'grey',
  border: 'solid 1px #aaa',
  height: 30,
}

const headerLogoStyle = {
  width: 300,
  marginTop: -27,
  marginBottom: -27,
  paddingRight: 20,
  height: 85,
  fontSize: 26,
  lineHeight: "85px",
  color: 'white',
  textAlign: 'center'
}

const searchBoxStyle = {
  height: 30,
  border: 'solid 1px #aaa',
  marginTop: 5,
  marginRight: 150
}

const headerWrapperStyle = {
  display: 'inline-flex',
  height: 75,
  alignItems: 'center',
  width: '100%',
  scale: "80%",
  position: 'absolute',
  zIndex: 500
}

const StoryCreation = () => {
  return (
    <div style={{marginTop: 30, marginLeft: 50}}>
      <img
        alt=''
        src={shareStory}
        style={{width: 460, border: '1px solid grey'}}/>
    </div>
  )
}

const NewsItem = ({link}) => {
  return (
    <div style={newsItemWrapperStyle}>
      <div style={newsItemHeaderStyle}>
        <img
          src={george}
          alt=''
          style={{width: 40, height: 40, border: '1px solid grey'}}/>
        <div style={{marginLeft: 10}}>
          <div style={{color: link, fontWeight: 'bold', fontSize: 14}}>
            John Wayne
          </div>
          <span>
            <span style={{fontSize: 12, color: '#666666'}}>
              15 days ago
            </span>
            <span style={{color: link, fontSize: 12, marginLeft: 5}}>
              Board
            </span>
            <span style={{color: link, marginLeft: 5, fontSize: 12}}>
              Shareholders
            </span>
          </span>
        </div>
      </div>
      <div style={{height: 180}}>
        <img src={video} style={{height: 380, width:460}} alt=''/>
      </div>
      <div style={{marginTop: 200}}>
        <img src={comments} style={{width:460}} alt=''/>
      </div>
    </div>
  )
}

const Body = ({primaryColor, link}) => {
  return (
    <div style={{display: 'inline-flex', position: 'absolute', left: 0, top: 75, right: 0, height: 500}}>
      <div style={{width: 200, height: 600, backgroundColor: 'white'}}>
        <img src={topics} style={{width: 200}} alt=''/>
      </div>
      <div style={{width: 550, height: 630, backgroundColor: '#f0f2f5', overflow: 'hidden'}}>
        <StoryCreation />
        <NewsItem link={link} primaryColor={primaryColor}/>
      </div>
      <div style={{width: 290, height: 630, backgroundColor: '#f0f2f5'}} >
        <div style={{width: 260, height: 90, backgroundColor: 'white', marginTop: 30}}>
          <div style={{height: 40, backgroundColor: primaryColor, color: 'white', padding: 8}}>
            Featured Items
          </div>
          <div style={{height: 20, padding: 8}}>
            Our vision and Values
          </div>

        </div>
        <div style={{width: 260, height: 210, backgroundColor: 'white', marginTop: 30}}>
          <div style={{height: 40, backgroundColor: primaryColor, color: 'white', padding: 8}}>
            Useful links
          </div>
          <div style={{height: 150, padding: 8, fontSize: 14, color: link}}>
            <div>• Emergency contacts</div>
            <div>• Policies and procedures</div>
            <div>• Key dates 2017</div>
            <div>• Staff directory</div>
            <div>• If you need help </div>
            <div>• Archives </div>
            <div>• Meeting minutes </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = ({headerBackground, headerLogo}) => {
  return (
    <div style={{...headerWrapperStyle, backgroundColor: headerBackground, paddingRight: 20}}>
      {
        headerLogo ? (
          <div style={{background: `transparent url(${headerLogo}) no-repeat left center`, ...headerLogoStyle}} alt=''></div>
        ) : (
          <div style={headerLogoStyle}>Family</div>
        )
      }
      <div style={{marginRight: 20, marginTop: 10}}><Plus /></div>
      <div>
        <img src={search} style={searchBoxStyle} alt=''/>
      </div>
      <div>
        <div style={otherStuff}>
          <img
            src={topRightStuff}
            alt=''
            style={{width: 200, height: 30, marginRight: 30}}/>
        </div>
      </div>
    </div>
  )
}

const Newsfeed = (props) => {
  return (
    <>
      <h2>Website Header</h2>
      <div style={{overflowY: 'hidden', height: 75 + 500, position: 'relative'}}>
        <Header {...props} />
        <Body {...props} />
      </div>
    </>
   )
}

export default Newsfeed
