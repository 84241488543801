import React from 'react'
import George from '../utilities/images/george.jpg'
import MobileNewsfeed from '../utilities/images/mobile_newsfeed.png'


const outerWrapperStyle = {
  width: '100%',
  display: 'flex',
  gap: 1,
}

const colStyle = {
  flexGrow: 1,
}

const loginStyle = {
  borderRadius: 8,
  width: 400,
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}

const appStyle = {
  width: 400,
  marginLeft: 'auto',
  marginRight: 'auto',
}

const inputStyle = {
  borderRadius: 7,
  backgroundColor: 'white',
  height: 130,
  width: 350,
  textAlign: 'left',
  padding: 10,
  marginBottom: 25,
}

const profileStyle = {
  width: 50,
  height: 50,
  borderRadius: 25,
  marginRight: 10
}

const Iphone = (props) => {
  const {primaryColor, mobileLogo, mobileHeaderLogo} = props

  return (
    <div style={outerWrapperStyle}>
      <div style={colStyle}>
        <h2>Sign in &amp; More page</h2>
        <div style={{...loginStyle, backgroundColor: primaryColor}}>
          <div style={{maxHeight: 75, maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 25}}>
            {mobileLogo ? <img src={mobileLogo} alt='' style={{maxWidth: 'inherit', maxHeight: 'inherit'}}/> :
            <div style={{color: 'white', fontSize: 20}}>Family Name</div>}
          </div>
          <div style={inputStyle}>
            <div style={{borderBottom: '1px solid #9B9B9B', paddingBottom: 10, display: 'flex', alignItems: 'center'}}>
              <img src={George} style={profileStyle} alt='' />
              <span>John Wayne</span>
            </div>
            <div style={{paddingTop: 15, color: '#9B9B9B'}}>Enter your password</div>
          </div>
        </div>
      </div>

      <div style={colStyle}>
        <h2>Home screen</h2>
        <div style={{...appStyle, backgroundColor: primaryColor}}>
          <div style={{height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{flexGrow: 1}}>
              {
                mobileHeaderLogo ? (
                  <img src={mobileHeaderLogo} alt='' style={{maxWidth: 250, display: 'block', height: 60}}/>
                ) : (
                  <div style={{color: 'white', fontSize: 20, paddingLeft: '1rem'}}>Family Name</div>
                )
              }
            </div>
            <div style={{color: 'white', fontSize: 20, paddingRight: '1rem'}}>
              Icons here
            </div>
          </div>
          <div >
            <img src={MobileNewsfeed} style={{width: '100%'}} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Iphone
