const createUrl = (props) => {
  const {
    name,
    primaryColor,
    link,
    headerBackground,
    publicBackground,
    headerLogo,
    loginBrandImage,
    mobileLogo,
    mobileHeaderLogo,
    createdAt
  } = props

  return new Blob(
    [JSON.stringify({
      name,
      created_at: createdAt || new Date(),
      updated_at: new Date(),
      colors: {
        primary: primaryColor,
        link,
        header_background: headerBackground,
        public_background: publicBackground,
      },
      files: {
        logo: headerLogo,
        side_login: loginBrandImage,
        mobile_logo: mobileLogo,
        mobile_header: mobileHeaderLogo,
      }
    })], {type: "application/json"})
}

export default createUrl
