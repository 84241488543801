import React from 'react'

const tabStyle = {
  border: '1px solid #ccc',
  borderRadius: 5,
  cursor: 'pointer',
  padding: 10,
  backgroundColor: 'white'
}

const clickedTabStyle = {
  backgroundColor: '#32b643',
  color: 'white'
}


const Tab = ({name, onClick, clicked  }) => {
  const style = clicked ? {...tabStyle, ...clickedTabStyle} : tabStyle
  return (
    <div
      style={style}
      onClick={onClick}>
      {name}
    </div>
  )
}

const Tabs = ({showPage: {page}, setShowPage}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1rem', paddingBottom: '1rem', gap: '1rem', borderBottom: '2px solid #ccc'}}>
      <Tab
        name='Website Header'
        clicked={page === 'HomePage'}
        onClick={() => setShowPage({page: 'HomePage'})} />
      <Tab
        name='Login Page'
        clicked={page === 'LoginPage'}
        onClick={() => setShowPage({page: 'LoginPage'})}/>
      <Tab
        name='Mobile (iPhone)'
        clicked={page === 'Iphone'}
        onClick={() => setShowPage({page: 'Iphone'})}/>
    </div>
  )
}

export default Tabs
