import React, {useState} from 'react'
import ColorPickerModal from './color_picker_modal'

const colorBarStyle = {
  marginTop: 5,
  height: 42,
  display: 'block',
  borderWidth: 0,
  width: '100%',
  cursor: 'pointer'
}

const TemplateColor = ({name, color, onChange}) => {
  const [colorPicker, toggleColorPicker] = useState(false),
    [positionInfo, setPositionInfo] = useState(false)

  return (
    <div>
      <label>
        {name}
      </label>
      <button
        onClick={() => toggleColorPicker(!colorPicker)}
        style={{...colorBarStyle, backgroundColor: color}}
      >
        <div
          ref={el => { el && !positionInfo && setPositionInfo(el.getBoundingClientRect()) }}
        >
          <div style={{padding: 5, color: 'white', mixBlendMode: 'difference', fontWeight: 'bold'}}>
          {color}
          </div>
        </div>
      </button>
      {colorPicker && positionInfo &&
        <ColorPickerModal
          onChange={onChange}
          color={color}
          toggleColorPicker={toggleColorPicker}
          left={positionInfo.width + positionInfo.left - 12}
          top={positionInfo.top + 12}
          colorPicker={colorPicker} /> }
    </div>
  )
}

export default TemplateColor
