import React from 'react'
import login from '../utilities/images/login.png'

const loginPageWrapperStyle = {
  height: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const brandImageWrapperStyle = {
  backgroundColor: '#efefef',
  minWidth: 450,
  minHeight: 400,
  borderRight: '1px solid black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  WebkitAlignItems: 'center',
  MozAlignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
  backgroundSize: 'cover',
  overflow: 'hidden',
}

const loginWrapperStyle = {
  height: 28,
  width: 56,
  fontWeight: 400,
  fontSize: 16,
  color: 'white',
  marginLeft: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
  marginTop: 18
}

const LoginPage = ({publicBackground, primaryColor, link, brandImage}) => {
  return (
    <>
      <h2>Login page</h2>
      <div style={{...loginPageWrapperStyle, backgroundColor: publicBackground}}>
        {brandImage ? (<span style={{...brandImageWrapperStyle, backgroundImage: `url(${brandImage})`}}>
        </span>) :
        false}
        <span style={{backgroundColor: 'white', width: 450, height: 400}}>
          <img src={login} style={{width: 450, paddingTop: 30}} alt='' />
          <div style={{display: 'inline-flex'}}>
            <div style={{...loginWrapperStyle, backgroundColor: primaryColor}}>
              Log in
            </div>
            <div style={{color: link, fontSize: 16, paddingTop: 20, paddingLeft: 270}}>
              I cant login
            </div>
          </div>
        </span>
      </div>
    </>
  )
}

export default LoginPage
