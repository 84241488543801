const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const encodeImage = async(image) => {
  if (!image) return null
  return await fileToBase64(image)
}

export default encodeImage
