import React from 'react'

const AddCircle = ({size = 42}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="-5 0 65 65">
      <defs></defs>
      <g
        transform="translate(-366.000000, -91.000000)"
        stroke="#212121"
        strokeOpacity="0.75">
        <g transform="translate(35.000000, 76.000000)">
          <g transform="translate(52.000000, 0.000000)">
            <g transform="translate(0.000000, 7.000000)">
              <g transform="translate(280.000000, 9.500000)">
                <circle
                  stroke='inherit'
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillOpacity="0.75"
                  cx="24.5"
                  cy="24.5"
                  r="24.5">
                </circle>
                <path
                  d="M14.7,24.5 L34.3,24.5"
                  stroke='inherit'
                  strokeWidth="3">
                </path>
                <path
                  d="M24.5,14.7 L24.5,34.3"
                  stroke='inherit'
                  strokeWidth="3">
                </path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AddCircle
