import React from 'react'
import Newsfeed from './newsfeed'
import LoginPage from './login_page'
import Iphone from './iphone'

const Preview = (props) => {
  const {showPage: {page}} = props
  return (
    <div>
      {page === "HomePage" && <Newsfeed {...props} />}
      {page === "LoginPage" && <LoginPage {...props} />}
      {page === "Iphone" && <Iphone {...props} />}
    </div>
  )
}

export default Preview
