import React from 'react'
import Modal from 'react-modal'
import { SketchPicker } from 'react-color'

const colorPickerStyle = ({top, left}) => ({
  content : {
    top,
    left,
    padding               : 0,
    right                 : 'auto',
    bottom                : 'auto',
    // transform             : 'translate(-50%, -50%)'
  },
  overlay: {
    // backgroundColor: ''
  }
})

const ColorPickerModal = ({color, onChange, toggleColorPicker, colorPicker, top, left}) => {
  return (
    <Modal
      isOpen={colorPicker}
      onRequestClose={() => toggleColorPicker(false)}
      style={colorPickerStyle({top, left})} >
      <SketchPicker color={color} onChange={(c) => onChange(c.hex)}/>
    </Modal>
  )
}

export default ColorPickerModal
