import React from 'react'

const TemplateUpload = (props) => {
  const {
    setName,
    setPrimaryColor,
    setlinkColor,
    setHeaderBackgroundColor,
    setpublicBackgroundColor,
    setLogo,
    setLoginBrandImage,
    setMobileLogo,
    setMobileHeaderLogo,
    setCreatedAt
  } = props

  return (
    <div>
      <input
        type='file'
        name='file'
        hidden
        id='file'
        onChange={(e) => {
          const file = e.target.files[0],
            fileReader = new FileReader()
            fileReader.readAsText(file, "UTF-8")
            fileReader.onload = function (evt) {
              const contents = JSON.parse(evt.target.result),
                {
                  name,
                  created_at,
                  colors: {primary, link, header_background, public_background},
                  files: {logo, side_login, mobile_logo, mobile_header}
              } = contents
              setName(name)
              setPrimaryColor(primary)
              setlinkColor(link)
              setHeaderBackgroundColor(header_background)
              setpublicBackgroundColor(public_background)
              setLogo(logo)
              setLoginBrandImage(side_login)
              setMobileLogo(mobile_logo)
              setMobileHeaderLogo(mobile_header)
              setCreatedAt(created_at)
            }
        }} />
      <label
        for="file"
        className={'btn btn-lg btn-success'}>Upload Template</label>
    </div>
  )
}

export default TemplateUpload
